<!--模板-->
<template>
  <div class="">
    <div style="height: 50px"></div>
    <a-table :columns="columns" :data-source="data" :pagination="false" :bordered="true" :scroll="{ x: 1300, y: 550 }" :loading="loading">
      <div slot="checkContent" slot-scope="text, record">
        <div v-for="(item, index) in record.contentList" :key="index">{{ item.content }}</div>
      </div>
      <div slot="checkStandar" slot-scope="text, record">
        <div v-for="(item, index) in record.contentList" :key="index">
          <div
            v-for="(c, cIndex) in item.standardList"
            :key="'c' + cIndex"
            style="border-bottom: 0.5px solid #ccc; width: 100%"
          >
            {{ c.name }}
          </div>
        </div>
      </div>
      <div slot="checkOptions" slot-scope="text, record">
        <span v-if="record.contentScore && record.contentScore > 0 && record.deduScore && record.deduScore > 0" style="color: red">
          {{ text }} ( -{{ record.deduScore }}分)
        </span>
        <span v-else> {{ text }} ( {{ record.deduScore }}分) </span>
      </div>
      <div slot="pics" slot-scope="text, record">
        <div v-for="(item, index) in record.contentList" :key="index">
          <div
            v-for="(c, cIndex) in item.standardList"
            :key="'c' + cIndex"
            style="border-bottom: 0.5px solid #ccc; width: 100%"
          >
            <img
              v-for="(item, index) in c.pics ? c.pics.split(',') : []"
              :key="index"
              :src="item"
              style="width: 80px; height: 80px; margin-top: 15px"
            />
          </div>
        </div>
      </div>
      <div slot="video" slot-scope="text">
        <video :src="text" v-if="text" style="width: 80px; height: 80px" controls="controls" />
      </div>
      <div slot="isDeduct" slot-scope="text, record">
        <span v-if="record.deduScore > 0" style="color: red">
          {{ record.deduScore ? '√' : '' }}
        </span>
        <span v-else>
          {{ text ? '√' : '' }}
        </span>
      </div>
      <div slot="rectificationRequirement" slot-scope="text, record">
        <div v-for="(item, index) in record.contentList" :key="index">
          <div
            v-for="(c, cIndex) in item.standardList"
            :key="'c' + cIndex"
            style="border-bottom: 0.5px solid #ccc; width: 100%"
          >
            {{ c.rectificationRequirement }}
          </div>
        </div>
      </div>
      <div slot="inspector" slot-scope="text, record">
        <div v-for="(item, index) in record.contentList" :key="index">
          <div
            v-for="(c, cIndex) in item.standardList"
            :key="'c' + cIndex"
            style="border-bottom: 0.5px solid #ccc; width: 100%"
          >
            {{ c.inspector }}
          </div>
        </div>
      </div>
      <div slot="checkRequirement" slot-scope="text, record">
        <div v-for="(item, index) in record.contentList" :key="index">
          <div
            v-for="(c, cIndex) in item.standardList"
            :key="'c' + cIndex"
            style="border-bottom: 0.5px solid #ccc; width: 100%"
          >
            {{ c.checkRequirement }}
          </div>
        </div>
      </div>
    </a-table>
  </div>
</template>

<script>
// import component from '@/component/*.vue'
export default {
  data() {
    return {}
  },
  props: {
    columns: {
      type: Array,
      default: [],
    },
    data: {
      type: Array,
      default: [],
    },
    showModals: {
      type: Function,
      default: null,
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  computed: {},
  filters: {},
  methods: {
    showModal() {
      // this.$parent.showModal()
      this.showModals()
      // this.$emit('showModal')
    },
  },
  created() {},
  mounted() {},
}
</script>

<style lang="less" scoped>
// @import url(); 引入公共css
.right-top {
  margin-bottom: 53px;
  box-sizing: border-box;
  padding: 42px 30px 0 46px;
}
.search {
  display: flex;
  align-content: center;
}
.newBuild {
  display: flex;
  justify-content: flex-end;
}
</style>
